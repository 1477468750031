import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ExamParses } from '../models/exams';

@Injectable({
  providedIn: 'root'
})
export class ExamParsesEntityService extends BaseEntityService<ExamParses> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'examParse', plural: 'examParses', url: 'exam-parses' },
      routerParamsService
    );
    this.nameId = 'examParseId';
  }
}
