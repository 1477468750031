import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Medication } from '../models/previous-medication';

@Injectable({
  providedIn: 'root',
})
export class MedicationsEntityService extends BaseEntityService<Medication> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'medication', plural: 'medications' },
      routerParamsService
    );
    this.nameId = 'medicationId';
  }
}
