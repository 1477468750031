import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { MenuLayoutComponent } from './shared/components/layouts/menu-layout/menu-layout.component';
import { OrganizationInternalGuard } from './shared/guards/organization-internal.guard';
import { RedirectLoginGuard } from './shared/guards/redirect-login.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectLoginGuard],
    component: AuthLayoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'organizacoes',
    component: MenuLayoutComponent,
    canActivate: [OrganizationInternalGuard],
    loadChildren: () =>
      import('./views/organizations/organizations.module').then(
        m => m.OrganizationsModule,
      ),
    data: { title: 'Lista de organizações' },
  },
  {
    component: AuthLayoutComponent,
    canActivate: [],
    path: 'sessions',
    loadChildren: () =>
      import('./views/sessions/sessions.module').then(
        m => m.SessionsModule,
      ),
    data: { title: 'Session' },
  },
  {
    path: '**',
    redirectTo: 'sessions/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
