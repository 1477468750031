import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { PatientSummary } from '../models/patient-summary';
import { JwtAuthService } from '../../shared/services/auth/jwt-auth.service';
import { Observable } from 'rxjs';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientSummaryEntityService extends BaseEntityService<PatientSummary> {

  constructor(
    private jwtAuthService: JwtAuthService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'patientSummary', plural: 'patientSummary', url: 'patient-api/patient-summary' },
      routerParamsService
    );
    this.nameId = 'patientSummaryId';
  }

  getCurrent(): Observable<PatientSummary> {
    const { id } = this.jwtAuthService.getUser() || {};
    return this.getEntityById(id);
  }
}
